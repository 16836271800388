import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import GlobalShortcuts from '@atlassian/global-shortcuts';
import { teams } from '@atlassian/global-shortcuts/teams';
import { jira } from '@atlassian/global-shortcuts/jira';
import { loom } from '@atlassian/global-shortcuts/loom';

const globalAppShortcutsWrapper = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

export const GlobalAppShortcuts = () => {
	const intl = useIntl();
	return (
		<Box xcss={globalAppShortcutsWrapper}>
			<GlobalShortcuts
				shortcutItems={[
					jira(intl.formatMessage(i18n.globalAppShortcutsJiraApp), () => ''),
					loom(intl.formatMessage(i18n.globalAppShortcutsLoomApp), () => ''),
					teams(intl.formatMessage(i18n.globalAppShortcutsTeamsApp), () => ''),
				]}
			/>
		</Box>
	);
};

const i18n = defineMessages({
	globalAppShortcutsJiraApp: {
		id: 'side-navigation.global-app-shortcuts.jira-app',
		description: 'Jira product name for the global app shortcuts',
		defaultMessage: 'Jira',
	},
	globalAppShortcutsLoomApp: {
		id: 'side-navigation.global-app-shortcuts.loom-app',
		description: 'Loom product name for the global app shortcuts',
		defaultMessage: 'Loom',
	},
	globalAppShortcutsTeamsApp: {
		id: 'side-navigation.global-app-shortcuts.teams-app',
		description: 'Teams product name for the global app shortcuts',
		defaultMessage: 'Teams',
	},
});
